import { default as cookiesVUssYcC0ZuMeta } from "/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/cookies.vue?macro=true";
import { default as cookiesnioMtUYPuPMeta } from "/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/cookies.vue?macro=true";
import { default as index8Rx71Xfj6OMeta } from "/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/index.vue?macro=true";
import { default as legalkkzmiiiylhMeta } from "/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/legal.vue?macro=true";
import { default as portfolio_45aboutLKspd4GfpJMeta } from "/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-about.vue?macro=true";
import { default as portfolio_45feesoOknqicJsuMeta } from "/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-fees.vue?macro=true";
import { default as portfolio_45investorsUFBpxcKEoyMeta } from "/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-investors.vue?macro=true";
import { default as portfolio_45issuersk9U4ilgCmiMeta } from "/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-issuers.vue?macro=true";
import { default as portfolio_45membersdZnXDvNPsLMeta } from "/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-members.vue?macro=true";
import { default as indexToArdrAWobMeta } from "/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/index.vue?macro=true";
import { default as transformation_45of_45an_45edav_45into_45a_45reitbi6UjKUu5GMeta } from "/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/transformation-of-an-edav-into-a-reit.vue?macro=true";
import { default as portfolio_45partnersLaahVoghShMeta } from "/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-partners.vue?macro=true";
import { default as privacycOINQYlEN6Meta } from "/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/privacy.vue?macro=true";
import { default as indexVGq4reBlGVMeta } from "/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/index.vue?macro=true";
import { default as legalEf5V7lMuPvMeta } from "/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/legal.vue?macro=true";
import { default as portfolio_45aboutd4XcPOogDNMeta } from "/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-about.vue?macro=true";
import { default as portfolio_45feesVskZXb8oO6Meta } from "/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-fees.vue?macro=true";
import { default as portfolio_45investorsR7PmwGdDxJMeta } from "/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-investors.vue?macro=true";
import { default as portfolio_45issuersvVqgukuLONMeta } from "/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-issuers.vue?macro=true";
import { default as portfolio_45membersBDRCXDkGOxMeta } from "/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-members.vue?macro=true";
import { default as index6kV3Zif7otMeta } from "/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/index.vue?macro=true";
import { default as transformation_45of_45an_45edav_45into_45a_45reit9jmUDkNqyZMeta } from "/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/transformation-of-an-edav-into-a-reit.vue?macro=true";
import { default as portfolio_45partnersJoF8Ld3ItvMeta } from "/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-partners.vue?macro=true";
import { default as privacyMLTMzKYmObMeta } from "/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/privacy.vue?macro=true";
export default [
  {
    name: cookiesVUssYcC0ZuMeta?.name ?? "cookies",
    path: cookiesVUssYcC0ZuMeta?.path ?? "/cookies",
    meta: cookiesVUssYcC0ZuMeta || {},
    alias: cookiesVUssYcC0ZuMeta?.alias || [],
    redirect: cookiesVUssYcC0ZuMeta?.redirect,
    component: () => import("/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookiesnioMtUYPuPMeta?.name ?? "es-cookies",
    path: cookiesnioMtUYPuPMeta?.path ?? "/es/cookies",
    meta: cookiesnioMtUYPuPMeta || {},
    alias: cookiesnioMtUYPuPMeta?.alias || [],
    redirect: cookiesnioMtUYPuPMeta?.redirect,
    component: () => import("/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/cookies.vue").then(m => m.default || m)
  },
  {
    name: index8Rx71Xfj6OMeta?.name ?? "es",
    path: index8Rx71Xfj6OMeta?.path ?? "/es",
    meta: index8Rx71Xfj6OMeta || {},
    alias: index8Rx71Xfj6OMeta?.alias || [],
    redirect: index8Rx71Xfj6OMeta?.redirect,
    component: () => import("/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/index.vue").then(m => m.default || m)
  },
  {
    name: legalkkzmiiiylhMeta?.name ?? "es-legal",
    path: legalkkzmiiiylhMeta?.path ?? "/es/legal",
    meta: legalkkzmiiiylhMeta || {},
    alias: legalkkzmiiiylhMeta?.alias || [],
    redirect: legalkkzmiiiylhMeta?.redirect,
    component: () => import("/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/legal.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45aboutLKspd4GfpJMeta?.name ?? "es-portfolio-about",
    path: portfolio_45aboutLKspd4GfpJMeta?.path ?? "/es/portfolio-about",
    meta: portfolio_45aboutLKspd4GfpJMeta || {},
    alias: portfolio_45aboutLKspd4GfpJMeta?.alias || [],
    redirect: portfolio_45aboutLKspd4GfpJMeta?.redirect,
    component: () => import("/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-about.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45feesoOknqicJsuMeta?.name ?? "es-portfolio-fees",
    path: portfolio_45feesoOknqicJsuMeta?.path ?? "/es/portfolio-fees",
    meta: portfolio_45feesoOknqicJsuMeta || {},
    alias: portfolio_45feesoOknqicJsuMeta?.alias || [],
    redirect: portfolio_45feesoOknqicJsuMeta?.redirect,
    component: () => import("/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-fees.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45investorsUFBpxcKEoyMeta?.name ?? "es-portfolio-investors",
    path: portfolio_45investorsUFBpxcKEoyMeta?.path ?? "/es/portfolio-investors",
    meta: portfolio_45investorsUFBpxcKEoyMeta || {},
    alias: portfolio_45investorsUFBpxcKEoyMeta?.alias || [],
    redirect: portfolio_45investorsUFBpxcKEoyMeta?.redirect,
    component: () => import("/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-investors.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45issuersk9U4ilgCmiMeta?.name ?? "es-portfolio-issuers",
    path: portfolio_45issuersk9U4ilgCmiMeta?.path ?? "/es/portfolio-issuers",
    meta: portfolio_45issuersk9U4ilgCmiMeta || {},
    alias: portfolio_45issuersk9U4ilgCmiMeta?.alias || [],
    redirect: portfolio_45issuersk9U4ilgCmiMeta?.redirect,
    component: () => import("/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-issuers.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45membersdZnXDvNPsLMeta?.name ?? "es-portfolio-members",
    path: portfolio_45membersdZnXDvNPsLMeta?.path ?? "/es/portfolio-members",
    meta: portfolio_45membersdZnXDvNPsLMeta || {},
    alias: portfolio_45membersdZnXDvNPsLMeta?.alias || [],
    redirect: portfolio_45membersdZnXDvNPsLMeta?.redirect,
    component: () => import("/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-members.vue").then(m => m.default || m)
  },
  {
    name: indexToArdrAWobMeta?.name ?? "es-portfolio-news",
    path: indexToArdrAWobMeta?.path ?? "/es/portfolio-news",
    meta: indexToArdrAWobMeta || {},
    alias: indexToArdrAWobMeta?.alias || [],
    redirect: indexToArdrAWobMeta?.redirect,
    component: () => import("/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/index.vue").then(m => m.default || m)
  },
  {
    name: transformation_45of_45an_45edav_45into_45a_45reitbi6UjKUu5GMeta?.name ?? "es-portfolio-news-transformation-of-an-edav-into-a-reit",
    path: transformation_45of_45an_45edav_45into_45a_45reitbi6UjKUu5GMeta?.path ?? "/es/portfolio-news/transformation-of-an-edav-into-a-reit",
    meta: transformation_45of_45an_45edav_45into_45a_45reitbi6UjKUu5GMeta || {},
    alias: transformation_45of_45an_45edav_45into_45a_45reitbi6UjKUu5GMeta?.alias || [],
    redirect: transformation_45of_45an_45edav_45into_45a_45reitbi6UjKUu5GMeta?.redirect,
    component: () => import("/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/transformation-of-an-edav-into-a-reit.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45partnersLaahVoghShMeta?.name ?? "es-portfolio-partners",
    path: portfolio_45partnersLaahVoghShMeta?.path ?? "/es/portfolio-partners",
    meta: portfolio_45partnersLaahVoghShMeta || {},
    alias: portfolio_45partnersLaahVoghShMeta?.alias || [],
    redirect: portfolio_45partnersLaahVoghShMeta?.redirect,
    component: () => import("/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-partners.vue").then(m => m.default || m)
  },
  {
    name: privacycOINQYlEN6Meta?.name ?? "es-privacy",
    path: privacycOINQYlEN6Meta?.path ?? "/es/privacy",
    meta: privacycOINQYlEN6Meta || {},
    alias: privacycOINQYlEN6Meta?.alias || [],
    redirect: privacycOINQYlEN6Meta?.redirect,
    component: () => import("/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/privacy.vue").then(m => m.default || m)
  },
  {
    name: indexVGq4reBlGVMeta?.name ?? "index",
    path: indexVGq4reBlGVMeta?.path ?? "/",
    meta: indexVGq4reBlGVMeta || {},
    alias: indexVGq4reBlGVMeta?.alias || [],
    redirect: indexVGq4reBlGVMeta?.redirect,
    component: () => import("/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: legalEf5V7lMuPvMeta?.name ?? "legal",
    path: legalEf5V7lMuPvMeta?.path ?? "/legal",
    meta: legalEf5V7lMuPvMeta || {},
    alias: legalEf5V7lMuPvMeta?.alias || [],
    redirect: legalEf5V7lMuPvMeta?.redirect,
    component: () => import("/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/legal.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45aboutd4XcPOogDNMeta?.name ?? "portfolio-about",
    path: portfolio_45aboutd4XcPOogDNMeta?.path ?? "/portfolio-about",
    meta: portfolio_45aboutd4XcPOogDNMeta || {},
    alias: portfolio_45aboutd4XcPOogDNMeta?.alias || [],
    redirect: portfolio_45aboutd4XcPOogDNMeta?.redirect,
    component: () => import("/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-about.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45feesVskZXb8oO6Meta?.name ?? "portfolio-fees",
    path: portfolio_45feesVskZXb8oO6Meta?.path ?? "/portfolio-fees",
    meta: portfolio_45feesVskZXb8oO6Meta || {},
    alias: portfolio_45feesVskZXb8oO6Meta?.alias || [],
    redirect: portfolio_45feesVskZXb8oO6Meta?.redirect,
    component: () => import("/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-fees.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45investorsR7PmwGdDxJMeta?.name ?? "portfolio-investors",
    path: portfolio_45investorsR7PmwGdDxJMeta?.path ?? "/portfolio-investors",
    meta: portfolio_45investorsR7PmwGdDxJMeta || {},
    alias: portfolio_45investorsR7PmwGdDxJMeta?.alias || [],
    redirect: portfolio_45investorsR7PmwGdDxJMeta?.redirect,
    component: () => import("/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-investors.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45issuersvVqgukuLONMeta?.name ?? "portfolio-issuers",
    path: portfolio_45issuersvVqgukuLONMeta?.path ?? "/portfolio-issuers",
    meta: portfolio_45issuersvVqgukuLONMeta || {},
    alias: portfolio_45issuersvVqgukuLONMeta?.alias || [],
    redirect: portfolio_45issuersvVqgukuLONMeta?.redirect,
    component: () => import("/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-issuers.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45membersBDRCXDkGOxMeta?.name ?? "portfolio-members",
    path: portfolio_45membersBDRCXDkGOxMeta?.path ?? "/portfolio-members",
    meta: portfolio_45membersBDRCXDkGOxMeta || {},
    alias: portfolio_45membersBDRCXDkGOxMeta?.alias || [],
    redirect: portfolio_45membersBDRCXDkGOxMeta?.redirect,
    component: () => import("/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-members.vue").then(m => m.default || m)
  },
  {
    name: index6kV3Zif7otMeta?.name ?? "portfolio-news",
    path: index6kV3Zif7otMeta?.path ?? "/portfolio-news",
    meta: index6kV3Zif7otMeta || {},
    alias: index6kV3Zif7otMeta?.alias || [],
    redirect: index6kV3Zif7otMeta?.redirect,
    component: () => import("/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/index.vue").then(m => m.default || m)
  },
  {
    name: transformation_45of_45an_45edav_45into_45a_45reit9jmUDkNqyZMeta?.name ?? "portfolio-news-transformation-of-an-edav-into-a-reit",
    path: transformation_45of_45an_45edav_45into_45a_45reit9jmUDkNqyZMeta?.path ?? "/portfolio-news/transformation-of-an-edav-into-a-reit",
    meta: transformation_45of_45an_45edav_45into_45a_45reit9jmUDkNqyZMeta || {},
    alias: transformation_45of_45an_45edav_45into_45a_45reit9jmUDkNqyZMeta?.alias || [],
    redirect: transformation_45of_45an_45edav_45into_45a_45reit9jmUDkNqyZMeta?.redirect,
    component: () => import("/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/transformation-of-an-edav-into-a-reit.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45partnersJoF8Ld3ItvMeta?.name ?? "portfolio-partners",
    path: portfolio_45partnersJoF8Ld3ItvMeta?.path ?? "/portfolio-partners",
    meta: portfolio_45partnersJoF8Ld3ItvMeta || {},
    alias: portfolio_45partnersJoF8Ld3ItvMeta?.alias || [],
    redirect: portfolio_45partnersJoF8Ld3ItvMeta?.redirect,
    component: () => import("/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-partners.vue").then(m => m.default || m)
  },
  {
    name: privacyMLTMzKYmObMeta?.name ?? "privacy",
    path: privacyMLTMzKYmObMeta?.path ?? "/privacy",
    meta: privacyMLTMzKYmObMeta || {},
    alias: privacyMLTMzKYmObMeta?.alias || [],
    redirect: privacyMLTMzKYmObMeta?.redirect,
    component: () => import("/codebuild/output/src3844265084/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/privacy.vue").then(m => m.default || m)
  }
]